import { httpApps, httpDealer, httpAdmin } from "../helper/http-common";

class SelectYourDiamoiondServices {
  GetRingBuilderOpeningPage(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetRingBuilderOpeningPage",
      inputData
    );
  }
  GetRingBuilderSelectLabDiamond(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetRingBuilderSelectLabDiamond",
      inputData
    );
  }

  GetRingBuilderSelectMinedDiamond(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetRingBuilderSelectMinedDiamond",
      inputData
    );
  }

  GetAvailableRingbuilderFilters(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetAvailableRingbuilderFilters",
      inputData
    );
  }

  SendMailToRetailerForNoResults(inputData) {
    return httpApps.post(
      "RingBuilderForML/SendMailToRetailerForNoResults",
      inputData
    );
  }
  GetRingBuilderMinedCompareData(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetRingBuilderMinedCompareData",
      inputData
    );
  }

  GetRingBuilderLabCompareData(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetRingBuilderLabCompareData",
      inputData
    );
  }

  RingBuilderRequestCertificate(inputData) {
    return httpApps.post(
      "RingBuilderForML/RingBuilderRequestCertificate",
      inputData
    );
  }

  GetDealerInformation(inputData) {
    return httpApps.post("DiamondLink/GetDealerInformation", inputData);
  }
  GetDealerInformationJewelry(inputData) {
    return httpApps.post("DiamondLink/GetDealerInformationJewelry", inputData);
  }

  GetSimilarDiamondsByPage(inputData, pageName) {
    if (pageName == "Mined") {
      return httpApps.post(
        "RingBuilderForML/GetRingBuilderSelectMinedDiamond",
        inputData
      );
    } else {
      return httpApps.post(
        "RingBuilderForML/GetRingBuilderSelectLabDiamond",
        inputData
      );
    }
  }

  GetRingBuilderViewDetailsMineLabDiamond(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetRingBuilderViewDetailsMineLabDiamond",
      inputData
    );
  }

  GetDealerLocationData(inputData) {
    return httpApps.post("DiamondLinkForML/GetDealerLocationData", inputData);
  }
  ProductTracking(inputData) {
    return httpApps.post("DiamondLinkForML/ProductTracking", inputData);
  }
  DiamondTracking(inputData) {
    return httpApps.post("DiamondLinkForML/DiamondTracking", inputData);
  }
  GetMenuList(inputData) {
    return httpAdmin.post("/Tools/GetMenuListForML", inputData);
  }
  CompanyWhileLabel() {
    return httpDealer.get("/Dashboard/CompanyWhileLabelForML");
  }
  GetLanguage(inputData) {
    return httpAdmin.post(
      "/SelectLenguage/SelectlanguageForDelarForML",
      inputData
    );
  }
}
export default new SelectYourDiamoiondServices();
